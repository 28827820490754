<template>
  <ManForm v-bind="props" />
</template>

<script>
import ManForm from "@/components/forms/ManForm.vue";
export default {
  components: { ManForm },
  data() {
    return {
      props: {
        title: "Seçim Yönetimi",
        submit: (form) => {
          this.$store.dispatch("saveManagement",form);
        },
      },
    };
  },
};
</script>

<style scoped>
</style>