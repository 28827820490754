<template>
  <b-container class="mt-4">
    <b-modal id="singleDeletion" title="Dikkat!" @ok="deleteItem">
      <p class="my-4">Öğeyi silmek istediğinizden emin misiniz?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>
    <b-modal id="singleUpdate" title="Dikkat!" @ok="editItem">
      <p class="my-4">Öğeyi güncellemek istediğinizden emin misiniz?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>
    <b-modal id="errorModal" title="Dikkat!">
      <p class="my-4">Lütfen bir öğe seçiniz</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          Tamam
        </b-button>
      </template>
    </b-modal>
    <b-form @submit="onSubmit">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-2 text-white"></label>
        </template>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <InputTile label="Seçimler" :custom="true" :required="true">
          <b-form-select
            v-model="form.id"
            :options="selectionOptions"
            text-field="title"
            value-field="id"
            :select-size="8"
          ></b-form-select>
        </InputTile>
        <InputTile label="Kategori" :custom="true">
          <b-form-select
            v-model="form.category"
            :options="categoryOptions"
            required
          >
          </b-form-select
        ></InputTile>
        <InputTile
          label="Başlık"
          v-model="form.title"
          :required="true"
          type="text"
        />
        <b-row class="mt-4">
          <!--<b-button variant="dark" class="mr-2">Kategori Seç</b-button>-->
          <!-- BU butonun ne işe yaradığı bilinmiyor -->

          <b-button
            variant="danger"
            class="mr-2 ml-2"
            @click="showDeleteMessage"
            >Sil</b-button
          >
          <b-button
            variant="warning"
            type="submit"
            class="mr-2"
            @click="fromEdit = true"
            >Güncelle</b-button
          >
          <b-button
            variant="success"
            class="mr-2"
            type="submit"
            @click="fromEdit = false"
            >Ekle</b-button
          >
        </b-row>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import InputTile from "./common/InputTile.vue";
import { mapGetters } from "vuex";
import selectionConstants from "../../store/constants/selections";
export default {
  components: { InputTile },
  computed: {
    ...mapGetters({ getSelectionOptions: "getManagementItems" }),
    selectionOptions: {
      get() {
        //this.getSelectionOptions.map((e)=>{return{title: e.title +" - "+ this.categoryOptions.find(x => x.value === e.category).text,id:e.id}});

        return this.getSelectionOptions.map((e) => {
          return {
            title:
              e.title +
              "-" +
              this.categoryOptions.find((x) => x.value == e.category).text,
            id: e.id,
          };
        });
      },
    },
  },
  name: "ManForm",
  data() {
    return {
      form: {
        category: null, //Kategori
        id: null, //id
        title: "", //Baslik
        value: "", //Deger
      },
      fromEdit: false,

      categoryOptions: [
        {
          text: "Branş",
          value: selectionConstants.BRANCH,
        },
        {
          text: "Kategori",
          value: selectionConstants.CATEGORY,
        },
        {
          text: "Kurul",
          value: selectionConstants.ASSEMBLY,
        },
        {
          text: "Mesafe",
          value: selectionConstants.DISTANCE,
        },
        {
          text: "Tekne Sınıfı",
          value: selectionConstants.BOAT_CLASS,
        },
        {
          text: "Tür",
          value: selectionConstants.TYPE,
        },
        {
          text: "Uyruk",
          value: selectionConstants.NATIONALITY,
        },
      ],
    };
  },
  props: ["title", "submit"],
  created() {
    this.$store.dispatch("initManagement");
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.fromEdit) {
        this.showEditMessage();
      } else {
        this.form.id = null;
        this.submit(this.form);
      }
    },
    showDeleteMessage() {
      if (!this.form.id) {
        this.$bvModal.show("errorModal");
      } else {
        this.$bvModal.show("singleDeletion");
      }
    },
    deleteItem() {
      this.$store.dispatch("deleteManagementItem", this.form.id);
      this.form.id = null;
    },
    showEditMessage() {
      if (!this.form.id) {
        this.$bvModal.show("errorModal");
      } else {
        this.$bvModal.show("singleUpdate");
      }
    },

    editItem() {
      this.$store.dispatch("editManagementItem", this.form);
      this.form.id = null;
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>